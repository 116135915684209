/* Status Button Styles */
.status-button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    transition: all 200ms;
    min-width: 110px;
    text-align: center;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    font-weight: 500;
}

.status-button.open:hover { background-color: rgb(28 56 41); }
.status-button.open.active { 
    background-color: rgb(28 56 41);
    color: rgb(74 222 128);
}

.status-button.assigned:hover { background-color: rgb(30 58 138); }
.status-button.assigned.active {
    background-color: rgb(30 58 138);
    color: rgb(96 165 250);
}

.status-button.inprogress:hover { background-color: rgb(146 64 14); }
.status-button.inprogress.active {
    background-color: rgb(146 64 14);
    color: rgb(251 191 36);
}

.status-button.resolved:hover { background-color: rgb(6 95 70); }
.status-button.resolved.active {
    background-color: rgb(6 95 70);
    color: rgb(52 211 153);
}

.status-button.closed:hover { background-color: rgb(127 29 29); }
.status-button.closed.active {
    background-color: rgb(127 29 29);
    color: rgb(248 113 113);
}

.status-button:not(.active) {
    background-color: #2A2A2A;
    color: rgb(156, 163, 175);
}
.status-button:not(.active):hover {
    color: white;
    background-color: #3A3A3A;
}

/* Status Dot Styles */
.status-dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 9999px;
}

.status-dot.open { background-color: rgb(59, 130, 246); }
.status-dot.assigned { background-color: rgb(168, 85, 247); }
.status-dot.inprogress { background-color: rgb(250, 204, 21); }
.status-dot.resolved { background-color: rgb(34, 197, 94); }
.status-dot.closed { background-color: rgb(156, 163, 175); }

/* Dropdown Styles */
.status-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0.5rem;
    width: 14rem;
    background-color: #2A2A2A;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 20;
    padding: 0.5rem 0;
    border: 1px solid rgba(255, 255, 255, 0.08);
}

.status-checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.625rem 1rem;
    color: rgb(156, 163, 175);
    cursor: pointer;
}
.status-checkbox-label:hover {
    background-color: #3A3A3A;
    color: white;
}

.status-checkbox {
    border-radius: 0.25rem;
    border-color: rgb(75, 85, 99);
    background-color: #1A1A1A;
}
.status-checkbox:focus {
    --tw-ring-color: rgb(59, 130, 246);
    --tw-ring-offset: 0;
} 