.switch-root {
    position: relative;
    display: inline-flex;
    height: 24px;
    width: 44px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 9999px;
    border: 2px solid transparent;
    transition: all 200ms ease-in-out;
    background-color: #4b5563;
    outline: none;
}

.switch-root:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
}

.switch-root[data-state='checked'] {
    background-color: #22c55e;
}

.switch-thumb {
    pointer-events: none;
    display: inline-block;
    height: 20px;
    width: 20px;
    transform: translateX(0);
    border-radius: 9999px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 200ms ease-in-out;
}

.switch-root[data-state='checked'] .switch-thumb {
    transform: translateX(20px);
} 